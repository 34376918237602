import { mql } from '../lib/utils';

const dirControls = {

	init () {
		this.bindUIActions();
	},

	bindUIActions() {
		$(document).on('keydown', '.dir-button', this.onKeyDown.bind(this));
	},

	onKeyDown(e) {
		const code = (e.keyCode) ? e.keyCode : e.which;

		if (mql.medium.matches) {
			let $dirControls = $(document.activeElement).closest('.dir-controls');

			if ($dirControls.length) {
				if (code === 37 || code === 39) {
					let $item;

					if (code === 37) { // Left arrow (37)
						$item = $(document.activeElement).parent().prev();
					} else if (code === 39) { // Right arrow (39)
						$item = $(document.activeElement).parent().next();
					}

					$item.find('.dir-button', $dirControls).focus();
				}
			}
		}
	}

};

export { dirControls };