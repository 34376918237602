const alert = {

	settings: {
		duration: 425,
		easing: 'swing'
	},

	elem: {
		$alert: $('#alert'),
		$masthead: $('.masthead')
	},

	init() {
		this.bindUIActions();
		this.offset();
	},

	bindUIActions() {
		this.elem.$alert
			.on('click', '.alert__trigger', this.onClick.bind(this))
			.on('mouseleave', '.alert__trigger', (e) => $(e.target).blur());

	},

	offset() {
		if (this.elem.$alert.length && (this.elem.$masthead.length > 0 === false)) {
			$('.level').addClass('level--has-alert');
		}
	},

	onClick(e) {
		const $alert = $(e.target).closest('.alert');
		const $content = $alert.find('.alert__content');

		// Current target
		$(e.target).attr('aria-expanded', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');
		$(e.target).attr('aria-pressed', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');

		// Component
		$alert.toggleClass('alert--is-active');

		// Content
		$content.attr('aria-hidden', (i, currentValue) => (currentValue === 'true') ? 'false' : 'true');
		$content.slideToggle(this.settings.duration, this.settings.easing);
	}

};

export { alert };
