import { mql } from '../lib/utils';
import { dirControls } from './dir-controls';

const timeline = {

	elem: {
		$slider: ($('.timeline .slider:not(.slider--large)').length > 0) ? $('.timeline .slider:not(.slider--large)') : null,
		$sliderLarge: ($('.timeline .slider.slider--large').length > 0) ? $('.timeline .slider.slider--large') : null
	},

	options: {
		adaptiveHeight: true,
		arrows: false,
		dots: false,
		draggable: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 425,
		rows: 0,
		infinite: false,
		responsive: [
			{
				breakpoint: 1920, // 1440px - 1919px
				settings: {
					slidesToShow: 3,
					slidesToScoll: 1,
					infinite: false
				}
			},
			{
				breakpoint: 1440, // 768px - 1439px
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: false
				}
			},
			{
				breakpoint: 768, // 0 - 767px
				settings: 'unslick'
			}
		]
	},

	optionsLarge: {
		adaptiveHeight: true,
		arrows: false,
		dots: false,
		draggable: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		speed: 425,
		rows: 0,
		infinite: false,
		responsive: [
			{
				breakpoint: 1920, // 1280px - 1919px
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: false
				}
			},			
			{
				breakpoint: 1280, // 960px - 1279px
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: false
				}
			},
			{
				breakpoint: 960, // 768px - 959px
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: false
				}
			},			
			{
				breakpoint: 768, // 0 - 767px
				settings: 'unslick'
			}
		]		
	},

	init() {
		// Modules
		dirControls.init();

		this.bindUIActions();
		this.toggleDirControls(mql.xlarge, this.elem.$sliderLarge, 4); // Large
		this.toggleDirControls(mql.xxlarge, this.elem.$slider, 3); // Default (Small)
	},

	bindUIActions() {
		const _this = this; // Context

		if (this.elem.$slider !== null) {
			this.elem.$slider.each(function () {
				if ($(this).children('.slider__slide').length >= 3) {
					$(this).on('init', _this.onSliderInit).on('init reinit afterChange', _this.onSliderChange);
					$(this).slick(_this.options);
					$(this).on('mouseleave', '.dir-button', (e) => $(e.target).blur());
				}
			});
		}

		if (this.elem.$sliderLarge !== null) {
			this.elem.$sliderLarge.each(function () {
				if ($(this).children('.slider__slide').length >= 4) {
					$(this)
						.on('init', _this.onSliderInit)
						.on('init reinit afterChange', _this.onSliderChange);
					$(this).slick(_this.optionsLarge);
					$(this).on('mouseleave', '.dir-button', (e) => $(e.target).blur());
				}
			});
		}

		mql.medium.addListener(this.onResize.bind(this));

		mql.xlarge.addListener((mq) => {
			this.toggleDirControls.call(this, mq, this.elem.$sliderLarge, 4);
		});

		mql.xxlarge.addListener((mq) => {
			this.toggleDirControls.call(this, mq, this.elem.$slider, 3);
		});
	},

	onResize(mq) {
		const _this = this; // Context

		if (mq.matches) {
			if (this.elem.$slider !== null) {
				this.elem.$slider.each(function () {
					if ($(this).children('.slider__slide').length >= 3) {
						$(this).not('.slick-initialized').slick(_this.options);
					}
				});
			}

			if (this.elem.$sliderLarge !== null) {
				this.elem.$sliderLarge.each(function () {
					if ($(this).children('.slider__slide').length >= 4) {
						$(this).not('.slick-initialized').slick(_this.optionsLarge);
					}
				});
			}
		}
	},

	onSliderInit(event, slick) {
		let $slider = slick.$slider;
		let $dirControls = $slider.parent().find('.dir-controls');

		$dirControls.on('click', '.dir-button', (e) => {
			e.preventDefault();

			$slider.slick($(e.target).data('dir'));
		});
	},

	onSliderChange(event, slick, currentSlide) {
		let $slider = slick.$slider;
		let $dirControls = $slider.parent().find('.dir-controls');

		let isLarge = $(event.target).hasClass('slider--large');
		let i = ((currentSlide) ? currentSlide : 0) + 1;
		let slidesToShow = (mql.xxlarge.matches) ? 3 : 2; // Small (Default)

		// Large
		if (isLarge) {
			slidesToShow = (mql.xlarge.matches) ? 4 : (mql.xmedium.matches) ? 3 : 2;
		}

		$('.dir-button', $dirControls).removeClass('dir-button--is-disabled');

		if (i === 1) {
			$('.dir-button--prev', $dirControls).addClass('dir-button--is-disabled');
			
			$slider.removeClass('slider--is-visible');
		} else if ((i - 1) === slick.$slides.length - slidesToShow) {
			$('.dir-button--next', $dirControls).addClass('dir-button--is-disabled');
			
			$slider.addClass('slider--is-visible');
		} else {
			$slider.removeClass('slider--is-visible');
		}
	},

	toggleDirControls(mq, elem, min) {
		if ($(elem) !== null) {
			if ($(elem).find('.slider__slide').length <= min) {
				let $timeline  = $(elem).parent('.timeline');
				let $dirControls = $timeline.find('.dir-controls');

				if (mq.matches) {
					setTimeout(function () {
						$(elem).addClass('slider--is-visible');
					}, 100);

					$dirControls.addClass('dir-controls--is-hidden');
				} else {
					setTimeout(function () {
						$(elem).removeClass('slider--is-visible');
					}, 100);
					
					$dirControls.removeClass('dir-controls--is-hidden');
				}
			}
		}
	}

};

export { timeline };
