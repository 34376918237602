import { mql } from '../lib/utils';
import { audienceNav } from './audience-nav';
import { primaryNav } from './primary-nav';
import { search } from './search';

const globalHeader = {

	elem: {
		$globalHeader: $('#global-header')
	},

	init() {
		// Modules
		audienceNav.init();
		primaryNav.init();
		search.init();

		this.bindUIActions();
		this.onResize(mql.large);
	},

	bindUIActions() {
		this.elem.$globalHeader
			.on('click', '#hamburger', this.menuToggle.bind(this))
			.on('mouseleave', '#hamburger', (e) => $(e.target).blur());

		mql.large.addListener(this.onResize.bind(this));
	},

	menuToggle(e) {
		const $target = $(e.target);
		const $menu = this.elem.$globalHeader.find('.global-header__menu');

		$target
			.attr('aria-expanded', (i, txt) => (txt === 'false') ? 'true' : 'false')
			.attr('aria-label', (i, txt) => (txt.indexOf('Open') > -1) ? txt.replace('Open', 'Close') : txt.replace('Close', 'Open'))
			.toggleClass('hamburger--is-active');

		$menu.toggleClass('global-header__menu--is-active');

		$('html, body').toggleClass('body--no-scroll');
	},

	onResize(mq) {
		const $menu = this.elem.$globalHeader.find('.global-header__menu');
		const isActive = $menu.hasClass('global-header__menu--is-active');

		if (mq.matches) {
			$menu.removeClass('global-header__menu--is-animatable');
		} else {
			$menu.addClass('global-header__menu--is-animatable');
		}

		if (mq.matches && isActive) {
			$('html, body').removeClass('body--no-scroll');
		} else if (!mq.matches && isActive) {
			$('html, body').addClass('body--no-scroll');
		} else {
			$('html, body').removeClass('body--no-scroll');
		}
	}

};

export { globalHeader };